import './sentry';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { APIProvider } from '@vis.gl/react-google-maps';

import App from './App.jsx';

import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from './theme';
import './i18n';

const GoogleMapsAPIkey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

// Global styles
export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${() => theme.colors.white} !important;    
    font-family: ${() => theme.fonts.default} !important;
    font-size: ${() => theme.fontSizes.default} !important;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0 !important;
    color: ${() => theme.colors.black};
  }

  input, textarea {
    font-family: ${() => theme.fonts.default} !important;
    color: ${() => theme.colors.black} !important;
  }

  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: center ;
  }

  #root {
    height: 100%;
    max-width: 600px;
    margin: auto;
  }

  [class*='StepButtonContent-'] {
    color: rgb(21, 21, 21);
    font-size: 1rem;
    font-variant-numeric: tabular-nums;
  }
  [class*='ConnectorContainer-'] {
    top: calc(1em - 1px);
    left: calc(-50% + 2em - 8px);
    right: calc(50% + 2em - 8px);
    position: absolute;
  }
  [class*='Connector-'] {
    display: block;
    border-color: rgb(21, 21, 21);
    border-top-style: solid;
    border-top-width: 2px;
  }
  
  [class*='StepButton-'].active {
    background-color: rgb(255, 243, 8);
  }
  
  [class*='Label-'] {
    margin-top: 4px !important;
  }

  [class*='StepMain-'] {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  ol {
    counter-reset: step-counter;
  }

  strong {
    font-family: 'FSMillbankWeb-Bold';
    font-weight: bold;
  }

`;

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <APIProvider
        apiKey={GoogleMapsAPIkey}
        onLoad={() => console.log('Maps API has loaded.')}
      >
        <App />
      </APIProvider>
    </ThemeProvider>
  </StrictMode>,
);
