import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header';
import TitleLeft from '../../components/TitleLeft';
import SubTitleLeft from '../../components/SubTitleLeft';
import Paragraph from '../../components/Paragraph';
import ContentContainer from '../../components/ContentContainer';
import MainContainer from '../../components/MainContainer';
import TermsContainer from '../../components/TermsContainer';
import FullWidthButton from '../../components/FullWidthButton';
import MarkdownContent from '../../components/MarkdownContent';
import * as Analytics from '../../helpers/analytics';

import { validateToken } from '../../api';

import { Steps, StepsList, StepsListItem, Link, LinkSeparator } from './styled';

const WelcomeScreen = () => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [logoSrc, setLogoSrc] = useState(null);
  const [customInstructions, setCustomInstructions] = useState(null);
  const [customWelcomeMessage, setCustomWelcomeMessage] = useState(null);
  const [customConfirmationMessage, setCustomConfirmationMessage] =
    useState(null);
  const [shouldValidatePostcode, setShouldValidatePostcode] = useState(false);
  const [addressCompleted, setAddressCompleted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const urlToken = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    const validate = async () => {
      const result = await validateToken(urlToken);
      if (result?.data) {
        setLogoSrc(result.data.logo);
        setCustomInstructions(result.data.customVideoInstructions);
        setCustomWelcomeMessage(result.data.customWelcomeMessage);
        setCustomConfirmationMessage(result.data.customConfirmationMessage);
        setShouldValidatePostcode(result.data.validatePostcode);
        setAddressCompleted(result.data.addressCompleted || false);

        Analytics.trackPage('show-me-submission-page-view', {});
      }
      setIsValid(!!result);
      setIsLoading(false);
    };

    validate();
  }, [urlToken]);

  const handleStart = () => {
    const state = {
      token: urlToken,
      logoSrc,
      customInstructions,
      customConfirmationMessage,
      shouldValidatePostcode,
    };

    Analytics.trackEvent('show-me-submission-started', {});
    if (!addressCompleted) {
      navigate('/address-input', { state });
      return;
    }

    // If there are custom instructions, show them first
    if (customInstructions) {
      navigate('/custom-video-instructions', { state });
    } else {
      navigate('/video-instructions', { state });
    }
  };

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  if (!isValid) {
    return <div>{t('common.invalidUrl')}</div>;
  }

  return (
    <>
      <Header logoSrc={logoSrc} />
      <MainContainer>
        <ContentContainer>
          {customWelcomeMessage ? (
            <MarkdownContent content={customWelcomeMessage} />
          ) : (
            <>
              <TitleLeft>{t('welcome.title')}</TitleLeft>
              <Paragraph>{t('welcome.description')}</Paragraph>
              <Steps>
                <SubTitleLeft>{t('welcome.steps.title')}</SubTitleLeft>
                <StepsList>
                  <StepsListItem>
                    <div>{t('welcome.steps.locate')}</div>
                  </StepsListItem>
                  <StepsListItem>{t('welcome.steps.film')}</StepsListItem>
                  <StepsListItem>{t('welcome.steps.send')}</StepsListItem>
                </StepsList>
              </Steps>
            </>
          )}
        </ContentContainer>
      </MainContainer>
      <TermsContainer>
        <Link href='#'>{t('welcome.links.terms')}</Link>
        <LinkSeparator>|</LinkSeparator>
        <Link href='#'>{t('welcome.links.privacy')}</Link>
      </TermsContainer>
      <FullWidthButton onClick={handleStart}>
        {t('welcome.startButton')}
      </FullWidthButton>
    </>
  );
};

export default WelcomeScreen;
