import * as Sentry from '@sentry/react';

const sentryEnv = import.meta.env.VITE_SENTRY_ENV;

if (!!sentryEnv && sentryEnv !== 'development') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: sentryEnv,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const error = (err, info = {}) => {
  if (!sentryEnv || sentryEnv === 'development') {
    console.error(err, info);
  } else {
    // Sending extra information changed in Sentry SDK v5.x
    // https://github.com/getsentry/sentry-javascript/blob/master/MIGRATION.md#new-scope-functions
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(err);
    });
  }
};

export const setContext = (context) => {
  Object.keys(context).forEach((property) => {
    if (property === 'user') {
      Sentry.setUser(context[property]);
    }
  });
};

export const info = (err, info = {}) => {
  if (!sentryEnv || sentryEnv === 'development') {
    console.error(err, info);
  } else {
    // Sending extra information changed in Sentry SDK v5.x
    // https://github.com/getsentry/sentry-javascript/blob/master/MIGRATION.md#new-scope-functions
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      scope.setLevel('info');
      Sentry.captureException(err);
    });
  }
};
