import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { MarkdownContainer } from './styled';
import './markdown.css';

const MarkdownContent = ({ content }) => {
  if (!content) return null;

  return (
    <MarkdownContainer>
      <Markdown
        components={{
          a: ({ href, children, ...props }) => (
            <a href={href} target='_blank' rel='noopener noreferrer' {...props}>
              {children}
            </a>
          ),
        }}
        className='reactMarkDown'
        rehypePlugins={[rehypeRaw]}
      >
        {content}
      </Markdown>
    </MarkdownContainer>
  );
};

export default MarkdownContent;
