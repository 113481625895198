import React, { ComponentProps } from 'react';
import * as Logger from '../sentry';

const addTraitsToProperties = (properties) => {
  const propertiesWithTraits = { ...properties };
  if (window?.localStorage?.ajs_user_traits) {
    try {
      const parsedTraits = JSON.parse(window.localStorage.ajs_user_traits);
      propertiesWithTraits.context = {
        ...parsedTraits.context,
      };
    } catch (err) {
      Logger.error(err);
    }
  }
  return propertiesWithTraits;
};

export const trackEvent = (name, properties = {}) => {
  (window as any).analytics.track(name, addTraitsToProperties(properties));
};

export const trackPage = (name, properties = {}) => {
  (window as any).analytics.page(name, addTraitsToProperties(properties));
};

export const withTrackEvent = (ComponentItem: React.ElementType) => {
  interface Props extends ComponentProps<typeof ComponentItem> {
    event?: string;
    eventProperties?: object;
    onClick?: Function;
  }
  const Sub = ({
    event,
    eventProperties,
    onClick,
    ...props
  }: Props): JSX.Element => {
    const handleOnClick = (e, ...rest) => {
      // Fire the parent function if it exists
      if (onClick && typeof onClick === 'function') {
        onClick(e, ...rest);
      }

      if (event) {
        trackEvent(event, eventProperties);
      }
    };

    return <ComponentItem onClick={handleOnClick} {...props} />;
  };

  return Sub;
};
