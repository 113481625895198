import { useCallback, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Analytics from '../../helpers/analytics';

import { updateReport } from '../../api';
import {
  isValidUkPostcode,
  getCoordinates,
  getLocationInfo,
} from '../../helpers';

import Button from '../../components/Button';
import ContentContainer from '../../components/ContentContainer';
import FullWidthButton from '../../components/FullWidthButton';
import ScrollToTop from '../../components/ScrollToTop';
import MainContainer from '../../components/MainContainer';
import Paragraph from '../../components/Paragraph';
import ProgressHeader from '../../components/ProgressHeader';
import TitleLeft from '../../components/TitleLeft';

import { Form, Input, FindMyLocationTextWrapper } from './styled';

const initialFormData = {
  addressLine1: '',
  addressLine2: '',
  townCity: '',
  postcode: '',
  latitude: '0.0',
  longitude: '0.0',
};

const AddressInputScreen = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [isManualInput, setIsManualInput] = useState(false);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const formRef = useRef(null);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setIsManualInput(true);
  }, []);

  const isFormValid = useCallback(() => {
    const { addressLine1, townCity, postcode, latitude, longitude } = formData;
    return (
      addressLine1 &&
      townCity &&
      postcode &&
      (state.shouldValidatePostcode ? isValidUkPostcode(postcode) : true) &&
      latitude &&
      longitude
    );
  }, [formData, state.shouldValidatePostcode]);

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setIsLoading(true);
      const updateSuccess = await updateReport(state.token, formData);
      setIsLoading(false);
      Analytics.trackEvent('show-me-location-input', {
        isManualInput,
      });
      if (updateSuccess) {
        navigate('/video-instructions', { state });
      }
    }
  };

  const formatFieldName = (fieldName) => t(`address.fields.${fieldName}.label`);

  const fetchLocation = async () => {
    setIsLocationLoading(true);
    try {
      const { latitude, longitude } = await getCoordinates();
      const locationInfo = await getLocationInfo(latitude, longitude);
      setFormData(locationInfo);
    } catch (error) {
      console.log('Error fetching location:', error);
    } finally {
      setIsLocationLoading(false);
    }
  };

  return (
    <>
      <ScrollToTop />
      <ProgressHeader step={0} />
      <MainContainer>
        <ContentContainer>
          <TitleLeft>{t('address.title')}</TitleLeft>
          <Paragraph>{t('address.description')}</Paragraph>
          <Button
            width='100%'
            onClick={fetchLocation}
            loading={isLocationLoading}
            disabled={
              isLocationLoading ||
              parseFloat(formData.latitude) ||
              parseFloat(formData.longitude)
            }
          >
            <FindMyLocationTextWrapper>
              {t('address.findLocation')}
            </FindMyLocationTextWrapper>
          </Button>
          <Form
            style={{ marginTop: '14px' }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {Object.entries(formData).map(([key, value]) => (
              <Input
                key={key}
                type='text'
                name={key}
                value={value}
                disabled={isLocationLoading}
                hidden={['latitude', 'longitude'].indexOf(key) > -1}
                onChange={handleChange}
                placeholder={formatFieldName(key)}
                required={key !== 'addressLine2'}
              />
            ))}
          </Form>
        </ContentContainer>
      </MainContainer>
      <FullWidthButton
        type='button'
        disabled={!isFormValid() || isLoading}
        onClick={handleButtonClick}
      >
        {isLoading ? t('common.submitting') : t('common.next')}
      </FullWidthButton>
    </>
  );
};

export default AddressInputScreen;
