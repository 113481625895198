import { useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dataURLtoBlob, getThumbnailImage } from '../../helpers/blobUtils';
import { uploadMedia, updateReport } from '../../api';
import ProgressHeader from '../../components/ProgressHeader';
import MainContainer from '../../components/MainContainer';
import ScrollToTop from '../../components/ScrollToTop';
import FullWidthButton from '../../components/FullWidthButton';
import ContentContainer from '../../components/ContentContainer';
import TitleLeft from '../../components/TitleLeft';
import AddVideoButton from '../../components/AddVideoButton';
import {
  ProgressBarContainer,
  ProgressBarOuter,
  ProgressBarInner,
  ProgressText,
  TextArea,
  SelectedVideo,
  HiddenInput,
  AdditionalMediaButtonContainer,
  Divider,
  SubHeadingParagraph,
  ThumbnailGrid,
} from './styled';
import placeholderImage from '../../assets/images/thumbnail-placeholder.jpg?base64';
import * as Analytics from '../../helpers/analytics';
const fileLimit = 1024 ** 3; // 1Gb

const AdditionalInfoScreen = () => {
  const { t } = useTranslation();
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const [videoThumbnails, setVideoThumbnails] = useState([state.thumbnail]);
  const [videos, setVideos] = useState([state.video]);
  const videoInputRef = useRef(null);

  const handleSubmit = useCallback(async () => {
    window.scrollTo(0, 0);
    setIsUploading(true);

    // Video + video thumbnail upload

    // Convert thumbnail data URL to Blob
    const videoThumbnailBlobs = videoThumbnails.map((x) => dataURLtoBlob(x));

    // Update report with "additional info"
    await updateReport(state.token, { additionalInfo });

    try {
      // Wait for both uploads to complete
      const videoUploadResponse = await uploadMedia(
        'videos',
        state.token,
        videos,
        videoThumbnailBlobs,
        setUploadProgress,
      );

      if (videoUploadResponse) {
        Analytics.trackEvent('show-me-video-submitted', {
          // TODO: Add details
        });

        navigate('/thank-you', {
          state: { ...state, referenceNo: videoUploadResponse.referenceNo },
        });
      } else {
        // Handle failure case
        alert('Failed to upload video.');
      }
    } finally {
      setIsUploading(false);
    }
  }, [videoThumbnails, state, videos, additionalInfo]);

  const handleAddVideoClick = useCallback(() => {
    videoInputRef.current.click();
  }, []);

  const snapImage = (videoElement) => {
    const image = getThumbnailImage(videoElement);
    setVideoThumbnails((previousState) => [...previousState, image]);
    URL.revokeObjectURL(videoElement.src);
  };

  const generateThumbnail = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = function () {
        const blob = new Blob([fileReader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        const videoElement = document.createElement('video');

        const timeoutId = setTimeout(() => {
          URL.revokeObjectURL(url);
          reject(new Error('Thumbnail generation timed out'));
        }, 5000);

        const handleError = () => {
          clearTimeout(timeoutId);
          URL.revokeObjectURL(url);
          reject(new Error('Failed to generate thumbnail'));
        };

        videoElement.addEventListener('error', handleError);
        videoElement.addEventListener('timeupdate', function timeupdate(event) {
          try {
            snapImage(event.target);
            event.target.removeEventListener('timeupdate', timeupdate);
            event.target.pause();
            clearTimeout(timeoutId);
            resolve();
          } catch (error) {
            handleError();
          }
        });

        videoElement.preload = 'metadata';
        videoElement.src = url;
        videoElement.muted = true;
        videoElement.playsInline = true;
        videoElement.play().catch(handleError);
      };

      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(file);
    });
  };

  const handleVideoChange = useCallback((e) => {
    Array.from(e.target.files).forEach((file) => {
      if (file && file.size > fileLimit) {
        alert('File too large!');
        return;
      }

      if (file) {
        setVideos((previousState) => [...previousState, file]);
        generateThumbnail(file).catch(() => {
          setVideoThumbnails((previousState) => [
            ...previousState,
            placeholderImage,
          ]);
        });
      }
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <ProgressHeader step={2} />
      <MainContainer>
        <ContentContainer>
          <TitleLeft>{t('additionalInfo.title')}</TitleLeft>
          <TextArea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            disabled={isUploading}
          />
          {isUploading ? (
            <ProgressBarContainer>
              <ProgressBarOuter>
                <ProgressBarInner progress={uploadProgress} />
              </ProgressBarOuter>
              <ProgressText>
                {t('additionalInfo.uploadProgress', {
                  progress: uploadProgress,
                })}
              </ProgressText>
            </ProgressBarContainer>
          ) : (
            <>
              <Divider />
              <TitleLeft>{t('additionalInfo.uploadTitle')}</TitleLeft>
              <SubHeadingParagraph>
                {t('additionalInfo.uploadDescription')}
              </SubHeadingParagraph>
              <AdditionalMediaButtonContainer>
                <AddVideoButton
                  onClick={handleAddVideoClick}
                  width='100%'
                  disabled={isUploading}
                >
                  {t('additionalInfo.buttons.addVideos')}
                </AddVideoButton>
                <HiddenInput
                  type='file'
                  accept='video/*'
                  onChange={handleVideoChange}
                  ref={videoInputRef}
                />
              </AdditionalMediaButtonContainer>
              <Divider />
              <TitleLeft>{t('additionalInfo.uploadsTitle')}</TitleLeft>
              <ThumbnailGrid>
                {videoThumbnails.map((thumb, i) => (
                  <SelectedVideo key={`mediathumb-${i}`}>
                    <img id={`mediathumb-${i}`} alt='Thumbnail' src={thumb} />
                  </SelectedVideo>
                ))}
              </ThumbnailGrid>
            </>
          )}
        </ContentContainer>
      </MainContainer>
      <FullWidthButton
        type='button'
        onClick={handleSubmit}
        disabled={!state.video || isUploading}
      >
        {isUploading ? t('common.uploading') : t('common.submit')}
      </FullWidthButton>
    </>
  );
};

export default AdditionalInfoScreen;
